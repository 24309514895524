<template>
    <div class="mainWrapper pb-0 welcomeCstm">
        <section class="blockElement space bg-white pb-custom16">
            <div class="container max-970">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <h1 class="bold mb-md-3">{{$t('welcomeC.welcomeText1')}}</h1>
                        <p>{{$t('welcomeC.welcomeText2')}}</p>
                        <div class="dualButton text-center my-5">
                            <a :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn me-3">{{$t('welcomeB.welcomeText3')}}</a>
                            <a :href="static_vars.domainURL+'register/'" class="button  zulu_btn border-button rounded">{{$t('welcomeB.welcomeText4')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space upPadding pt-0">
            <div class="container max-970">
                <div class="row justify-content-center">
                    <div class="col-12 text-center">
                        <div class="video-container position-relative">
                            <video class="zulu-video" autoplay="autoplay" loop="loop" muted="true" controls="controls">
                                <source src="/assets/video/zulutrade.mp4" type="video/mp4">
                                <source src="/assets/video/zulutrade.mp4" type="video/ogg">
                            </video>
                            <!-- <div id="video-audio"></div> -->

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="blockElement space pt-0 why-join-mob">
            <div class="container">
                <div class="description-container box-shadow mx-auto mb-5">
                    <div class="row">
                        <div class="col-12 col-md-6 position-relative">
                            <div class="description-strength wl2"></div>
                            <div class="align-items-center w-75 mx-auto">
                                <div class="col-12 mb-4 mb-md-5 text-center">
                                    <h2 class="title f-20 line-bottom">{{$t('welcomeC.welcomeText3')}}</h2>
                                </div>
                                <ul class="commonList">
                                    <li class="secondary">
                                        <p class="title medium text-center" v-html="$t('welcomeC.welcomeText4')"> </p>
                                    </li>
                                    <li class="secondary">
                                        <p class="title medium text-center">{{$t('welcomeC.welcomeText5')}}</p>
                                    </li>
                                    <li class="secondary">
                                        <p class="title medium text-center">{{$t('welcomeC.welcomeText6')}}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 position-relative border-left-custom hideborder">
                            <div class="description-guard w-l2">
                                <span class="icon wl2"></span>
                            </div>
                            <div class="align-items-center w-75 mx-auto">
                                <div class="col-12 mb-4 mb-md-5 text-center">
                                    <h2 class="title f-20 line-bottom">{{$t('welcomeC.welcomeText7')}}</h2>
                                </div>

                                <div class="col-12 col-md-12 d-flex mb-2">

                                    <span class="icon-custom-diagonal check_green me-1"></span>
                                    <div class="title medium w-90">
                                        <p class="title medium text-center">{{$t('welcomeC.welcomeText8')}}</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 d-flex mb-2">
                                    <span class="icon-custom-diagonal check_green me-1"></span>
                                    <div class="title medium w-90">
                                        <p class="title medium text-center">{{$t('welcomeC.welcomeText9')}}</p>
                                    </div>
                                </div>
                                <div class="col-16 col-md-12 d-flex mb-2">
                                    <span class="icon-custom-diagonal check_green me-1"></span>

                                    <div class="title medium w-90">
                                        <p class="title medium text-center">{{$t('welcomeC.welcomeText10')}}</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 d-flex mb-2">
                                    <span class="icon-custom-diagonal check_green me-1"></span>

                                    <div class="title medium w-90">
                                        <p class="title medium text-center">{{$t('welcomeC.welcomeText11')}}</p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 d-flex mb-2">
                                    <span class="icon-custom-diagonal check_green me-1"></span>

                                    <div class="title medium w-90">
                                        <p class="title medium text-center" v-html="$t('welcomeC.welcomeText12')"></p>
                                    </div>
                                </div>
                                <div class="col-12 col-md-12 d-flex mb-2">
                                    <span class="icon-custom-diagonal check_green me-1"></span>
                                    <div class="title medium w-90">
                                        <p class="title medium text-center" v-html="$t('welcomeC.welcomeText13')"></p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dualButton text-center my-5">
                        <a :href="static_vars.domainURL+'register/'" class="button fillBtn zulu_btn me-3">{{$t('welcomeB.welcomeText3')}}</a>
                        <a :href="static_vars.domainURL+'register/'" class="button  zulu_btn border-button rounded bg-white">{{$t('welcomeB.welcomeText4')}}</a>
                    </div>
                </div>
            </div>
        </section>


    </div>
</template>